import { state } from "@/plugins/state"

export function getPermissions(): string[] {
    if (state.token) {
        const payloadStart = state.token.indexOf(".")
        const payloadEnd = state.token.lastIndexOf(".")
        if (payloadStart > -1 && payloadEnd > payloadStart) {        
            const payload = atob(state.token.substring(
                payloadStart + 1, 
                payloadEnd
            ))
            return JSON.parse(payload).usr.permissions
        }
    }
    return []
}

export function hasPermission(permission: string): boolean {
    const userPermissions = getPermissions()
    if (userPermissions.includes(permission)) {
        return true
    }
    return false
}

export function getUserId(): number {
    if (state.token) {
        const payloadStart = state.token.indexOf(".")
        const payloadEnd = state.token.lastIndexOf(".")
        if (payloadStart > -1 && payloadEnd > payloadStart) {        
            const payload = atob(state.token.substring(
                payloadStart + 1, 
                payloadEnd
            ))
            return JSON.parse(payload).sub
        }
    }
    return 0
}