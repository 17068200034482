
import Vue from "vue"
import SelectSpace from "@/components/SelectSpace.vue"

export default Vue.extend({
  name: "ModalSpace",
  components: {
    SelectSpace
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    getSpaces() {
      this.dialog = true
    }
  }
})
