
import DateFormatMixin from "@/mixins/DateFormatMixin.vue";
import { EventBus } from "@/plugins/eventBus";
import { mutations, state } from "@/plugins/state";
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import Vue, { VueConstructor } from "vue"
import NotificationItem from "./NotificationItem.vue";

type NotificationData = {
  [key: string]: number|string
}

interface Notification {
  id: number,
  type: number,
  read: boolean,
  created_at: string,
  title: string,
  message: string,
  data: NotificationData,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: "Notification",
  mixins: [DateFormatMixin],
  components: {
    NotificationItem
  },
  data () {
    return {
      notifications: [] as Notification[],
      onlyUnreadFilter: state.showUnreadNotification,
      displayNotifications: false,
      displayOptions: false,
      timer: 0,
      hiddenNotifications: state.hiddenNotificationTypes as string[]
    }
  },
  computed: {
    unread: function(): number {
      return this.notifications.filter(notification => {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          return false
        }
        return !notification['read']
      }).length
    },
    displayedNotification: function(): {[key:string]: Notification[]} {
      // Apply filters
      let notifications =  this.notifications.filter((notification: Notification) => {
        if (this.hiddenNotifications.includes(String(notification.type))) {
          return false
        }
        if (this.onlyUnreadFilter) {
          return notification.read === false
        }
        return true
      })

      // Classify the notification based on date
      let result = {today: [] as Notification[], yesterday: [] as Notification[], older: [] as Notification[]}
      for (const notification of notifications) {
        if (this.qualifyDate(notification.created_at) === 'today') {
          result.today.push(notification)
        } else if (this.qualifyDate(notification.created_at) === 'yesterday') {
          result.yesterday.push(notification)
        } else {
          result.older.push(notification)
        }
      }
      return result
    }
  },
  mounted() {
    this.getNotifications()
    this.timer = setInterval(() => {
      this.getNotifications(true)
    }, 5000)
  },
  watch: {
    onlyUnreadFilter (val) {
      mutations.setShowUnreadNotification(val)
    },
    hiddenNotifications: {
      handler(){
        mutations.setHiddenNotificationTypes(this.hiddenNotifications)
      },
     deep: true
  }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    readNotification(notification: Notification) {
      axios.put(`/v1/notifications/${notification.id}`,
        {
          read: !notification.read
        }
      )
      .then(() => { // HTTP status between 200 & 299
        notification.read = !notification.read // reflect success on front data
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
      })
    },
    markAllAsRead() {
      for (const key in this.displayedNotification) {
        for (const notification of this.displayedNotification[key]) {
          this.readNotification(notification)
        }
      }
    },
    qualifyDate(date: string) {
      let dateToQualify = new Date(date)
      let today = new Date
      let yesterday = new Date
      yesterday.setDate(yesterday.getDate() - 1)

      if(dateToQualify.getFullYear() === today.getFullYear() &&
      dateToQualify.getMonth() === today.getMonth() &&
      dateToQualify.getDate() === today.getDate()) {
        return "today"
      } else if(dateToQualify.getFullYear() === yesterday.getFullYear() &&
      dateToQualify.getMonth() === yesterday.getMonth() &&
      dateToQualify.getDate() === yesterday.getDate()) {
        return "yesterday"
      } else {
        return "older"
      }
    },
    getNotifications(refresh = false) {
      let url = '/v1/notifications'
      if (refresh) {
        // We set the filter 5 seconds from now
        const date = new Date(Date.now() - 5000);
        const dateTimeString = format(date, 'yyyy-MM-dd HH:mm:ss')
        url += `?from=${dateTimeString}`
      }
      axios.get(url)
      .then(response => { // HTTP status between 200 & 299
        // get only notifications not already in this.notifications
        let newNotifications = response.data.filter((notification: Notification) => {
          return this.notifications.includes(notification) === false
        })
        // concatenate them
        this.notifications = newNotifications.concat(this.notifications)
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar',{axiosError: error})
      })
    }
  }
})
