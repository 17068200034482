import it from "vuetify/src/locale/it"

export default {
  ...it,
  locale: "Italiano",
  code: "it",
  or: "o",
  // Developement drawer menu & associated <h1> title
  welcome: "Bienvenue",
  home: "Accueil",
  about: "À propos",
  worker_list: "Liste du personnel",
  orders: "Commandes",
  localization_examples: "Examples de localisation",
  clients_list: "Liste de clients",
  expand_menu: "Étendre le menu",
  collapse_menu: "Réduire le menu",
  workers_list: "Liste du personnel",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "Retour à la version actuelle",
  logout: "Se déconnecter",
  user_settings: "Paramètres utilisateur",
  change_space: "Changer d'espace",
  // Dispojob API request known errors
  api_errors: {
    401: "Informations d'authentification invalides, veuillez vous reconnecter", // Unauthorized
    403: "Vous n'avez pas la permission d'utiliser cette ressource", // Forbidden
    404: "La ressource demandée n'existe pas", // Unauthorized
    415: "Format de fichier non supporté", // Unsupported Media Type
    502: "Problème de connexion à l'API, veuillez réessayer ou contacter le support", // Bad Gateway, happened while restarting docker container
    500: "Erreur API, veuillez contacter le support", // Internal server error
    network: "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    "TypeError: Failed to fetch": "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    unknown: "Erreur inconnue, veuillez réessayer ou contacter le support",
    "User has no rights": "Vous n'avez pas accès à la ressource",
    "Invalid status": "Le statut est invalide"
  },
  // /workers page
  id: "Id",
  space_id: "Id espace",
  workers: 'Personnels',
  birth_city: "Luogo di nascita",
  birth_country: "Paese di nascita",
  nir: "Numero di previdenza sociale",
  // /clients page
  name: "Nom",
  // Pagination component
  pagination_title: "Données paginées",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Poste",
  customer: "Client",
  creation_date: "Date de création",
  starting_date: "Date de début",
  ending_date: "Date de fin",
  // Themes
  theme_light: "Thème clair",
  theme_dark: "Thème sombre",
  // 404 page
  not_found: "Cette page n’a pas été trouvée",
  not_found_text1: "Merci de nous faire part de cette erreur en cliquant sur le lien suivant: ",
  not_found_text2: "Accès au portail servicedesk",
  not_found_text3: "Vous avez reçu un lien pour créer votre compte sur le servicedesk. Nous traiterons cette demande dans les plus brefs délais. N'hésitez pas à faire une capture d'écran afin de nous aider à résoudre l'incident au plus vite.",
  // /login page
  login_page: {
    welcome_message: "Bienvenue sur votre espace privé Dispojob. L'accès à votre compte est confidentiel.", 
    forgot_password: "Mot de passe oublié ?",
    login: "Connexion",
    log_in: "Se connecter",
    password: "Mot de passe",
    valid_password: "Mot de passe valide",
    min_char: "6 caractères au minimum",
    brochure: "Catalogue de formations en ligne"
  },
  // /inscription page
  subscription_page: {
    error: {
      unknown: "Si prega di controllare il modulo cliccando sul retro o di contattare l'agenzia per finalizzare l'iscrizione.",
      "Invalid email": "L'e-mail non è valida",
      "Field space_id is required": "Scegliere un spazio",
      "Invalid sex": "La civiltà non è valida",
      "Invalid first_name": "Il nome non è valido",
      "Invalid last_name": "Il cognome non è valido",
      "Invalid mobile_phone_number": "Il numero di cellulare non è valido",
      "Invalid postal_address": "L'indirizzo postale non è valido",
      "Invalid postal_complement": "Il complemento di indirizzo non è valido",
      "Invalid postal_code": "Il codice postale non è valido",
      "Invalid postal_city": "La città non è valida",
      "Invalid postal_country": "Il paese non è valido",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "La data di nascita non è valida",
      "Invalid birth_date (wrong value)": "La data di nascita non è valida",
      "Invalid birth_city": "Il luogo di nascita non è valido",
      "Invalid birth_country": "Il paese di nascita non è valido",
      "Invalid nir (Pattern)": "Il numero di assicurazione nazionale non è valido",
      "Invalid nir (Key)": "La chiave di verifica per questo numero di assicurazione nazionale non è valida.",
      "email or mobile_phone_number already taken": "Numero di e-mail o di cellulare già utilizzato",
      "Invalid favorite_jobs size": "I lavori devono avere una lunghezza compresa tra 2 e 255 caratteri.",
      "Invalid favorite_jobs type": "I posti di lavoro ricercati non possono essere numeri."
    },
    favorite_jobs: "Cercasi lavoro",
    non_compliant_file_error: "Errore, file non conforme",
    error_no_space: "Errore, non abbiamo trovato nessuna spazio che corrisponda al link inserito.",
    prepare_documents: "Preparare i documenti",
    mandatory_documents: "Documento obbligatorio",
    mandatory_resume: "È necessario fornire almeno il proprio CV",
    documents_complete_file: "Documento per un file completo",
    your_resume: "Il vostro CV",
    resume: "CV",
    identity_document: "Documento d'identità",
    social_security_document: "Carta vitale o certificato",
    residence_proof:"Prova di indirizzo",
    banque_identity: "RNL",
    work_certificate: "Certificato di lavoro",
    training_certificate: "Certificati di formazione",
    medical_checkup: "Visita/idoneità medica",
    documents_quality_info: "I documenti e le foto devono essere di buona qualità e ben incorniciati.",
    documents_format_info: "I file caricati devono essere di dimensioni inferiori a 8 MB e nei seguenti formati: .jpg, .jpeg, .png, .pdf, .doc, .docx, .odt o .rtf",
    complete_file_warning: "Quanto più completo è il vostro dossier, tanto prima avrete la possibilità di lavorare.",
    file_usage:"Inviando il presente modulo, acconsento che le informazioni inserite possano essere utilizzate in relazione alla mia candidatura, alla gestione della mia occupabilità e al rapporto contrattuale e/o commerciale che ne potrà derivare.",
    sign_in:"Iscriviti",
    marital_status_info: "Le seguenti informazioni sono raccolte al fine di dichiarare il vostro impiego in Francia. Se questo non è il vostro caso, cliccate su Continua. ",
    invalid_nir: "Questo numero di previdenza sociale non è valido",
    invalid_nir_key: "La chiave di verifica per questo numero di previdenza sociale non è valida.",
    other_id:"Altre identificazioni",
    other_documents: "Altro documento",
    confirmation: {
      title: "Congratulazioni, è fatta!",
      text_1: "Congratulazioni, la registrazione è stata completata.",
      text_2: "Il datore di lavoro o il selezionatore vi contatterà per il resto del processo di reclutamento.",
      text_3: "Buona fortuna e buon reclutamento.",
      video_1: "Video del funzionamento",
      video_2: "1 testo, 1 lavoro!",
      new_subscription: "Nuova registrazione"
    },
    cet_consent: "Voglio aprire un conto di risparmio a tempo.",
    kiosk_subscription: "Registrazione del terminale",
    portrait_guide: "Posizionare il viso al centro dell'obiettivo",
    scanner_guide: "Posizionare il documento sul supporto dello scanner",
    webcam_error: "Si è verificato un errore. Verificare le autorizzazioni della webcam o se viene utilizzata da un'altra fonte."
  },
  drag_and_drop: "trascinare e rilasciare",
  identity_card: "Carta d'identità",
  identity_card_verso: "Retro della carta d'identità",
  passport: "Passaporto",
  caces: "CACES",
  habilitation: "Abilitazione",
  license: "Licenza",
  diploma: "Diploma",
  driver_licence: "Patente di guida",
  other: "Altro",
  subscription: "Registrazione",
  photo: "Foto",
  birth_date:'Data di nascita',
  required_field: "Campo obbligatorio",
  preparation: "Preparazione",
  contact_details: "Dettagli di contatto",
  marital_status: "Stato civile",
  documents: "Documenti",
  resident_permit: "Permesso di soggiorno",
  confirmation: "Conferma",
  continue: "Continua",
  return: "Indietro",
  // Calendar
  fullcalendar: "Calendrier Complêt",
  // /calendar page
  status: "Statut",
  available: "Disponible",
  unavailable: "Indisponible",
  unknown:"sconosciuto",
  contract: "En contrat",
  time_start: "Heure de début",
  time_end: "Heure de fin",
  event_created: "Évènement créé",
  event_updated: "Évènement mis à jour",
  event_deleted: "Évènement supprimé",
  // Parameters
  language: 'Langue',
  // Generic terms
  title: "Titre",
  create: "Créer",
  delete: "Supprimer",
  update: "Mettre à jour",
  close: "Fermer",
  cancel: "Annuler",
  choose_space: "Choisir un espace",
  space: "Spazio",
  mandatory: "Obbligatorio",
  optional: "Opzionale",
  required: "Richiesto",
  email: "E-mail",
  phone: "Telefono",
  valid_email: "Indirizzo e-mail valido",
  invalid_email: "Indirizzo e-mail non valido",
  first_name: "Nome",
  firstname: "Nome",
  last_name: "Nome",
  lastname: "Nome",
  postal_address: "Indirizzo postale",
  postal_complement: "Indirizzo aggiuntivo",
  postal_code: "Codice postale",
  city: "Città",
  country: "Paese",
  civility: "La civiltà",
  mister: "Il signor",
  misses: "La signora",
  // transport
  means_transport: "Mezzi di trasporto",
  car: "auto",
  motorbike: "moto",
  bike_scooter: "bicicletta, monopattino",
  mass_transit: "trasporto pubblico",
  moped: "scooter",
  walk: "a piedi",
  carpool: "carpooling",
  password: "Password",
  none: "no",
  invalid_size_array: "Non è possibile aggiungere più di 3 operazioni.",
  // mobile phone number input
  mobile_phone_number: "Numero di cellulare",
  invalid_mobile_phone_number: "Numero di cellulare non valido",
  invalid_length: "La lunghezza del testo non è valida",
  is_numeric: "Questo campo deve contenere solo numeri interi",
  is_string: "Questo campo non deve contenere numeri",
  // Planning
  week: "Semaine",
  month: "Mois",
  date_from: "Du",
  date_to: "Au",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Administrateur application",
    "space-users": "Administrateur espace - CRUD utilisateurs",
    "jobs-delegate": "DPAE & Confirmation de mission",
    "orders": "Commandes",
    "multiposting": "Multidiffusion"
  },
  permissions_descriptions: {
    "app-admin": "A tous les droits sur toute l'application",
    "space-users": "Vous êtes administrateur de votre Espace, vous pouvez créer des utilisateurs, réinitialiser leur mot de passe, etc...",
    "jobs-delegate": "A le droit d'utiliser la fonctionnalité DPAE & Confirmation de mission",
    "orders": "A le droit d'utiliser la fonctionnalité Commandes",
    "multiposting": "A le droit d'utiliser l'application Multidiffusion"
  },
  // Admin
  admin: {
    title: "Administration application",
    users: {
      title: "Utilisateurs",
    }
  },
  // Space administration page
  space_admin: {
    title: "Administration espace",
    users: {
      title: "Utilisateurs",
    }
  },
  // User list
  user_list: "Liste des utilisateurs",
  permissions: "Permissions",
  spaces: "Spazio",
  groups: "Groupes",
  last_login_at: "Dernière connexion",
  v3_last_login_at: "Dernière connexion v3",
  deleted_at: 'Date de suppression',
  api_access: "Accès API",
  deleted_user: "Utilisateur supprimé",
  delete_users: "Supprimer les utilisateurs",
  deletion_successful: "Suppression effectuée avec succès",
  deletion_partial: "Suppression partiellement effectuée avec succès",
  // User creation
  user_creation: "Créer un utilisateur",
  user_API: "Créer un utilisateur API ?",
  is_user_admin: "Est-ce que le futur utilisateur sera un administrateur ?",
  user_successfully_created: "Utilisateur créé avec succès",
  // User_update
  user_update: "Mettre à jour un utilisateur",
  v1_admin_rights: "Donner a cet utilisateur les droits d'administration ?",
  user_successfully_updated: "Utilisateur mis à jour avec succès",
  // Paragon
  selection: "Sélection",
  select_page: "Sélectionner toute la page",
  select_all_filtered: "Sélectionner tous les résultats filtrés",
  select_file: "Selezionare un file",
  list: "Liste",
  asc: "Ascendant",
  desc: "Descendant",
  action: "Actions",
  filter: "Filtres",
  clear_filters: "Vider les filtres",
  search: "Rechercher",
  no_data_available: "Aucune donnée disponible",
  // Date
  from: "À partir du",
  to: "Jusqu'au",
  select_only_one_date: "Vous n'avez sélectionné qu'une date.",
  filter_without_date: "Filtrer les résultats sans dates ?",
  do_you_want: "Voulez-vous",
  // Worker List
  filter_by_first_last_name: "Filtrer par noms ou prénoms",
  separate_search_with_commas: "Séparez vos éléments de recherche (noms et prénoms) par des virgules pour une recherche multiple"
}
