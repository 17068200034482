
import Vue from "vue"
export default Vue.extend({
    name: "dateFormatMixin",
    methods: {
        localizeHour: function(stringDate: string) {
            let result = ''
            let date = new Date(stringDate)
            if (stringDate === null || date.toString() == "Invalid Date") {
                return result
            }
            let hour = this.$vuetify.lang.current === 'fr' ? 'h' : ':';
            result += [this.getTwoDigitsNumber(date.getHours()),
                this.getTwoDigitsNumber(date.getMinutes())].join(hour)
            return result
        },
        simplifyLocalizeDate: function (stringDate: string, showTime = true, numeric = false) {
            let result = ''
            let date = new Date(stringDate)
            if (stringDate === null || date.toString() == "Invalid Date") {
                return result
            }
            if (this.$vuetify.lang.current === 'fr') {
                result = [this.getTwoDigitsNumber(date.getDate()), // To get the two digits notation
                        this.getTwoDigitsNumber(date.getMonth()+1), 
                        this.getTwoDigitsNumber(date.getFullYear())].join('/')
                if (showTime) {
                    result += numeric ? " " : ' à '
                    result += [this.getTwoDigitsNumber(date.getHours()),
                        this.getTwoDigitsNumber(date.getMinutes())].join('h')
                }
            } else if (this.$vuetify.lang.current === 'en') {
                result = [this.getTwoDigitsNumber(date.getMonth()+1), 
                    this.getTwoDigitsNumber(date.getDate()), // To get the two digits notation
                    this.getTwoDigitsNumber(date.getFullYear())].join('/')
                if (showTime) {
                    result += numeric ? " " : ' at '
                    result += [this.getTwoDigitsNumber(date.getHours()),
                    this.getTwoDigitsNumber(date.getMinutes())].join('h')
                }
            }
            return result
        },
        localizeDate: function (stringDate: string, showTime = true, numeric = false) {
            let result = ''
            let date = new Date(stringDate)
            if (stringDate === null || date.toString() == "Invalid Date") {
                return result
            }

            if (this.$vuetify.lang.current === 'fr') {
                result = [this.getTwoDigitsNumber(date.getDate()), // To get the two digits notation
                    this.getTwoDigitsNumber(date.getMonth()+1), 
                    date.getFullYear()].join('/')
                if (showTime) {
                    result += numeric ? " " : ' à '
                    result += [this.getTwoDigitsNumber(date.getHours()),
                        this.getTwoDigitsNumber(date.getMinutes()),
                        this.getTwoDigitsNumber(date.getSeconds())].join(':')
                }
            } else if (this.$vuetify.lang.current === 'en') {
                result = [this.getTwoDigitsNumber(date.getMonth()+1), 
                    this.getTwoDigitsNumber(date.getDate()), // To get the two digits notation
                    date.getFullYear()].join('/')
                if (showTime) {
                    result += numeric ? " " : ' at '
                    result += [this.getTwoDigitsNumber(date.getHours()),
                    this.getTwoDigitsNumber(date.getMinutes()),
                    this.getTwoDigitsNumber(date.getSeconds())].join(':')
                }
            }
            return result
        },
        getTwoDigitsNumber: function(number: number) {
            return ('0' + number).slice(-2)
        },
        localizeRelativeDate: function (dateStr: string) {
            let date = new Date(dateStr)
            if (dateStr === null || date.toString() == "Invalid Date") {
                return ""
            }

            const diff = (new Date().getTime() - date.getTime()) / 1000
            const minutes = Math.floor(diff / 60)
            const hours = Math.floor(minutes / 60)
            const days = Math.floor(hours / 24)
            const months = Math.floor(days / 30)
            const years = Math.floor(months / 12)
            const rtf = new Intl.RelativeTimeFormat(this.$vuetify.lang.current)

            let value
            if (years > 0) {
                value = rtf.format(0 - years, "year")
            } else if (months > 0) {
                value = rtf.format(0 - months, "month")
            } else if (days > 0) {
                value = rtf.format(0 - days, "day")
            } else if (hours > 0) {
                value = rtf.format(0 - hours, "hour")
            } else if (minutes > 0) {
                value = rtf.format(0 - minutes, "minute")
            } else {
                value = rtf.format(Math.min(0 - Math.floor(diff), -1), "second") // we always display atleast one second ago
            }
            return value
        }
    }
})
