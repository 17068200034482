

import Vue from "vue"
import { hasPermission, getPermissions } from "@/plugins/token"

export default Vue.extend({
    name: "PermissionMixin",
    methods: {
        hasPermission,
        getPermissions
    }
})
