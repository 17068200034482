import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import { state } from "@/plugins/state"

Vue.use(Vuetify)

// Import locales
import * as importedLocales from "@/locale"

export default new Vuetify({
  theme: {
    dark: state.theme,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#c90828", // Red "Dispojob"
        secondary: "#424242", // Grey
        accent: "#82B1FF", // Blue (which tends towards lavender)
        error: "#ff4f54", // Light red (which tends towards pink)
        info: "#2a93ef", // Blue (which tends towards petrol)
        success: "#49b157", // Green
        warning: "#fb8c22" // Orange
      },
      dark: {
        primary: "#c90828",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#ff4f54",
        info: "#2a93ef",
        success: "#49b157",
        warning: "#fb8c22"
      },
    },
  },
  lang: {
    locales: importedLocales,
    current: state.locale, // get locale from store
  },
})
