import pt from "vuetify/src/locale/pt"

export default {
  ...pt,
  locale: "Português",
  code: "pt",
  or: "ou",
  // Developement drawer menu & associated <h1> title
  welcome: "Bienvenue",
  home: "Accueil",
  about: "À propos",
  worker_list: "Liste du personnel",
  orders: "Commandes",
  localization_examples: "Examples de localisation",
  clients_list: "Liste de clients",
  expand_menu: "Étendre le menu",
  collapse_menu: "Réduire le menu",
  workers_list: "Liste du personnel",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "Retour à la version actuelle",
  logout: "Se déconnecter",
  user_settings: "Paramètres utilisateur",
  change_space: "Changer d'espace",
  // Dispojob API request known errors
  api_errors: {
    401: "Informations d'authentification invalides, veuillez vous reconnecter", // Unauthorized
    403: "Vous n'avez pas la permission d'utiliser cette ressource", // Forbidden
    404: "La ressource demandée n'existe pas", // Unauthorized
    415: "Format de fichier non supporté", // Unsupported Media Type
    502: "Problème de connexion à l'API, veuillez réessayer ou contacter le support", // Bad Gateway, happened while restarting docker container
    500: "Erreur API, veuillez contacter le support", // Internal server error
    network: "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    "TypeError: Failed to fetch": "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    unknown: "Erreur inconnue, veuillez réessayer ou contacter le support",
    "User has no rights": "Vous n'avez pas accès à la ressource",
    "Invalid status": "Le statut est invalide"
  },
  // /workers page
  id: "Id",
  space_id: "Id espace",
  workers: 'Personnels',
  birth_city: "Local de nascimento",
  birth_country: "País de nascimento",
  nir: "Número de segurança social",
  // /clients page
  name: "Nom",
  // Pagination component
  pagination_title: "Données paginées",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Poste",
  customer: "Client",
  creation_date: "Date de création",
  starting_date: "Date de début",
  ending_date: "Date de fin",
  // Themes
  theme_light: "Thème clair",
  theme_dark: "Thème sombre",
  // 404 page
  not_found: "Cette page n’a pas été trouvée",
  not_found_text1: "Merci de nous faire part de cette erreur en cliquant sur le lien suivant: ",
  not_found_text2: "Accès au portail servicedesk",
  not_found_text3: "Vous avez reçu un lien pour créer votre compte sur le servicedesk. Nous traiterons cette demande dans les plus brefs délais. N'hésitez pas à faire une capture d'écran afin de nous aider à résoudre l'incident au plus vite.",
  // /login page
  login_page: {
    welcome_message: "Bienvenue sur votre espace privé Dispojob. L'accès à votre compte est confidentiel.", 
    forgot_password: "Mot de passe oublié ?",
    login: "Connexion",
    log_in: "Se connecter",
    password: "Mot de passe",
    valid_password: "Mot de passe valide",
    min_char: "6 caractères au minimum",
    brochure: "Catalogue de formations en ligne"
  },
  // /inscription page
  subscription_page: {
    error: {
      unknown: "Por favor, verifique o formulário clicando no verso ou contacte a agência para finalizar a sua inscrição.",
      "Invalid email": "O e-mail é inválido",
      "Field space_id is required": "Por favor escolha um espaço",
      "Invalid sex": "A Civilidade é inválida",
      "Invalid first_name": "O primeiro nome é inválido",
      "Invalid last_name": "O apelido é inválido",
      "Invalid mobile_phone_number": "O número de telemóvel é inválido",
      "Invalid postal_address": "O endereço postal é inválido",
      "Invalid postal_complement": "O complemento de endereço é inválido",
      "Invalid postal_code": "O Código Postal é inválido",
      "Invalid postal_city": "A cidade é inválida",
      "Invalid postal_country": "O país é inválido",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "Data de nascimento é inválida",
      "Invalid birth_date (wrong value)": "Data de nascimento é inválida",
      "Invalid birth_city": "O local de nascimento é inválido",
      "Invalid birth_country": "O país de nascimento é inválido",
      "Invalid nir (Pattern)": "O número do seguro nacional é inválido",
      "Invalid nir (Key)": "A chave de verificação para este número de seguro nacional é inválida",
      "email or mobile_phone_number already taken": "Email ou número de telemóvel já utilizado",
      "Invalid favorite_jobs size": "Os trabalhos devem ter entre 2 e 255 caracteres.",
      "Invalid favorite_jobs type": "Os empregos procurados não podem ser números."
    },
    favorite_jobs: "Procura-se emprego",
    non_compliant_file_error: "Erro, ficheiro não conforme",
    error_no_space: "Erro, não encontrámos nenhuma espaço que corresponda à ligação que introduziu.",
    prepare_documents: "Prepare os seus documentos",
    mandatory_documents: "Documento obrigatório",
    mandatory_resume: "Deve pelo menos fornecer o seu CV",
    documents_complete_file: "Documento para um ficheiro completo",
    your_resume: "O seu CV",
    resume: "CV",
    identity_document: "Documento de identidade",
    social_security_document: "Carte vitale ou certificado",
    residence_proof:"Comprovativo de morada",
    banque_identity: "extracto bancário",
    work_certificate: "Certificado de trabalho",
    training_certificate: "Certificados de formação",
    medical_checkup: "Visita/aptidão médica",
    documents_quality_info: "Os seus documentos e fotografias devem ser de boa qualidade e bem enquadrados.",
    documents_format_info: "Os ficheiros carregados devem ter menos de 8 MB de tamanho e nos seguintes formatos: .jpg, .jpeg, .png, .pdf, .doc, .docx, .odt ou .rtf",
    complete_file_warning: "Quanto mais completo for o seu ficheiro, mais cedo terá a oportunidade de trabalhar.",
    file_usage:"Ao enviar este formulário, concordo que as informações introduzidas podem ser utilizadas em relação à minha candidatura a emprego, à gestão da minha empregabilidade e à relação contratual e/ou comercial que possa resultar.",
    sign_in:"Inscrever-se",
    marital_status_info: "As seguintes informações são recolhidas a fim de declarar o seu emprego em França. Se este não for o seu caso, clique em Continuar ",
    invalid_nir: "Este número de segurança social é inválido",
    invalid_nir_key: "A chave de verificação para este número de segurança social é inválida",
    other_id:"Outra identificação",
    other_documents: "Outro documento",
    confirmation: {
      title: "Parabéns, está feito!",
      text_1: "Parabéns, o registo está agora completo.",
      text_2: "O seu empregador ou recrutador irá contactá-lo para o resto do seu processo de recrutamento.",
      text_3: "Boa sorte e bom recrutamento.",
      video_1: "Vídeo da operação",
      video_2: "1 Texto, 1 Trabalho!",
      new_subscription: "Novo registo"
    },
    cet_consent: "Quero abrir uma conta poupança-reforma.",
    kiosk_subscription: "Registo no terminal",
    portrait_guide: "Coloque o seu rosto no centro da lente",
    scanner_guide: "Colocar o documento no suporte do scanner",
    webcam_error: "Ocorreu um erro. Por favor verifique as permissões da webcam ou se está a ser utilizada por outra fonte."
  },
  drag_and_drop: "arrastar e largar",
  identity_card: "Cartão de identidade",
  identity_card_verso: "Cartão de identidade de volta",
  passport: "Passaporte",
  caces: "CACES",
  habilitation: "Habilitação",
  license: "Licença",
  diploma: "Diploma",
  driver_licence: "Carta de Condução",
  other: "Outros",
  subscription: "Inscrição",
  photo: "Foto",
  birth_date:'Data de nascimento',
  required_field: "Campo obrigatório",
  preparation: "Preparação",
  contact_details: "Detalhes de contacto",
  marital_status: "Estado civil",
  documents: "Documentos",
  resident_permit: "Autorização de residência",
  confirmation: "Confirmação",
  continue: "Continuar",
  return: "Voltar",
  // Calendar
  fullcalendar: "Calendrier Complêt",
  // /calendar page
  status: "Statut",
  available: "Disponible",
  unavailable: "Indisponible",
  unknown: "desconhecido",
  contract: "En contrat",
  time_start: "Heure de début",
  time_end: "Heure de fin",
  event_created: "Évènement créé",
  event_updated: "Évènement mis à jour",
  event_deleted: "Évènement supprimé",
  // Parameters
  language: 'Langue',
  // Generic terms
  title: "Titre",
  create: "Créer",
  delete: "Supprimer",
  update: "Mettre à jour",
  close: "Fermer",
  cancel: "Annuler",
  choose_space: "Choisir un espace",
  space: "área",
  mandatory: "Obrigatório",
  optional: "Opcional",
  required: "Requerido",
  email: "Correio electrónico",
  phone: "Telefone",
  valid_email: "Endereço de correio electrónico válido",
  invalid_email: "Endereço de e-mail inválido",
  first_name: "Primeiro nome",
  firstname: "Primeiro nome",
  last_name: "Nome",
  lastname: "Nome",
  postal_address: "Endereço postal",
  postal_complement: "Endereço adicional",
  postal_code: "Código postal",
  city: "Cidade",
  country: "País",
  civility: "Civilidade",
  mister: "Senhor",
  misses: "Senhora",
  // transport
  means_transport: "Meios de transporte",
  car: "automóvel",
  motorbike: "motocicleta",
  bike_scooter: "bicicleta, trotinete",
  mass_transit: "transportes públicos",
  moped: "scooter",
  walk: "a pé",
  carpool: "partilha de boleias",
  password: "Senha",
  none: "não",
  invalid_size_array: "Não pode adicionar mais do que 3 ofícios.",
  // mobile phone number input
  mobile_phone_number: "Número de telefone móvel",
  invalid_mobile_phone_number: "Número de telemóvel inválido",
  invalid_length: "A extensão do texto não é válida",
  is_numeric: "Este campo deve conter apenas números inteiros",
  is_string: "Este campo não deve conter números",
  // Planning
  week: "Semaine",
  month: "Mois",
  date_from: "Du",
  date_to: "Au",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Administrateur application",
    "space-users": "Administrateur espace - CRUD utilisateurs",
    "jobs-delegate": "DPAE & Confirmation de mission",
    "orders": "Commandes",
    "multiposting": "Multidiffusion"
  },
  permissions_descriptions: {
    "app-admin": "A tous les droits sur toute l'application",
    "space-users": "Vous êtes administrateur de votre Espace, vous pouvez créer des utilisateurs, réinitialiser leur mot de passe, etc...",
    "jobs-delegate": "A le droit d'utiliser la fonctionnalité DPAE & Confirmation de mission",
    "orders": "A le droit d'utiliser la fonctionnalité Commandes",
    "multiposting": "A le droit d'utiliser l'application Multidiffusion"
  },
  // Admin
  admin: {
    title: "Administration application",
    users: {
      title: "Utilisateurs",
    }
  },
  // Space administration page
  space_admin: {
    title: "Administration espace",
    users: {
      title: "Utilisateurs",
    }
  },
  // User list
  user_list: "Liste des utilisateurs",
  permissions: "Permissions",
  spaces: "Espaço",
  groups: "Groupes",
  last_login_at: "Dernière connexion",
  v3_last_login_at: "Dernière connexion v3",
  deleted_at: 'Date de suppression',
  api_access: "Accès API",
  deleted_user: "Utilisateur supprimé",
  delete_users: "Supprimer les utilisateurs",
  deletion_successful: "Suppression effectuée avec succès",
  deletion_partial: "Suppression partiellement effectuée avec succès",
  // User creation
  user_creation: "Créer un utilisateur",
  user_API: "Créer un utilisateur API ?",
  is_user_admin: "Est-ce que le futur utilisateur sera un administrateur ?",
  user_successfully_created: "Utilisateur créé avec succès",
  // User_update
  user_update: "Mettre à jour un utilisateur",
  v1_admin_rights: "Donner a cet utilisateur les droits d'administration ?",
  user_successfully_updated: "Utilisateur mis à jour avec succès",
  // Paragon
  selection: "Sélection",
  select_page: "Sélectionner toute la page",
  select_all_filtered: "Sélectionner tous les résultats filtrés",
  select_file: "Seleccione um ficheiro",
  list: "Liste",
  asc: "Ascendant",
  desc: "Descendant",
  action: "Actions",
  filter: "Filtres",
  clear_filters: "Vider les filtres",
  search: "Rechercher",
  no_data_available: "Aucune donnée disponible",
  // Date
  from: "À partir du",
  to: "Jusqu'au",
  select_only_one_date: "Vous n'avez sélectionné qu'une date.",
  filter_without_date: "Filtrer les résultats sans dates ?",
  do_you_want: "Voulez-vous",
  // Worker List
  filter_by_first_last_name: "Filtrer par noms ou prénoms",
  separate_search_with_commas: "Séparez vos éléments de recherche (noms et prénoms) par des virgules pour une recherche multiple"
}
