import Vue from "vue"
import App from "@/App.vue"
import router from "@/router"
import vuetify from "@/plugins/vuetify"
import "@/sass/main.sass"
import axios from "axios"
import VueGtag from "vue-gtag"
import { state, mutations } from "@/plugins/state"
import VueAuthImage from "vue-auth-image"
import { TiptapVuetifyPlugin } from "tiptap-vuetify"

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-N8D9LEEXZT" }
}, router)
Vue.use(VueAuthImage)
Vue.use(TiptapVuetifyPlugin, {
  vuetify, // provide vuetify to tiptap to use its components
  iconsGroup: 'mdi'
})

// configure axios default parameters
axios.defaults.baseURL = process.env.VUE_APP_API_URL // see .env.development & .env.production files

// Add a request interceptor which remove all query parameters with "" value
// NOTE - please note that axios is already removing parameters with null value
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  if ("params" in config) {
    config.params = Object.fromEntries(Object.entries(config.params).filter(([param, value]) => param == param && value !== "")) // remove keys with "" value from params
  }

  if(config.headers) {
    // Add current route path
    config.headers['X-Route-Path'] = router.currentRoute.fullPath
    // Add JWT token if any
    if (state.token) {
      config.headers.Authorization = `Bearer ${state.token}`
    }
  }

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401 && router.currentRoute.path !== "/login" ) {
      setTimeout(() => { //Leaving time for user to check error snackbar before redirection
        mutations.logout()
        router.push('/login')
      }, 3000)
  }
  return Promise.reject(error)
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
