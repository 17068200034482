import de from "vuetify/src/locale/de"

export default {
  ...de,
  locale: "Deutsch",
  code: "de",
  or: "oder",
  // Developement drawer menu & associated <h1> title
  welcome: "Bienvenue",
  home: "Accueil",
  about: "À propos",
  worker_list: "Liste du personnel",
  orders: "Commandes",
  localization_examples: "Examples de localisation",
  clients_list: "Liste de clients",
  expand_menu: "Étendre le menu",
  collapse_menu: "Réduire le menu",
  workers_list: "Liste du personnel",
  // Production drawer menu & associated <h1> title
  delegates: "DPAE & Confirmation",
  back_to_v1: "Retour à la version actuelle",
  logout: "Se déconnecter",
  user_settings: "Paramètres utilisateur",
  change_space: "Changer d'espace",
  // Dispojob API request known errors
  api_errors: {
    401: "Informations d'authentification invalides, veuillez vous reconnecter", // Unauthorized
    403: "Vous n'avez pas la permission d'utiliser cette ressource", // Forbidden
    404: "La ressource demandée n'existe pas", // Unauthorized
    415: "Format de fichier non supporté", // Unsupported Media Type
    502: "Problème de connexion à l'API, veuillez réessayer ou contacter le support", // Bad Gateway, happened while restarting docker container
    500: "Erreur API, veuillez contacter le support", // Internal server error
    network: "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    "TypeError: Failed to fetch": "Problème de connexion à l'API, veuillez réessayer ou contacter le support",
    unknown: "Erreur inconnue, veuillez réessayer ou contacter le support",
    "User has no rights": "Vous n'avez pas accès à la ressource",
    "Invalid status": "Le statut est invalide"
  },
  // /workers page
  id: "Id",
  space_id: "Id espace",
  workers: 'Personnels',
  birth_city: "Geburtsort",
  birth_country: "Geburtsland",
  nir: "Sozialversicherungsnummer",
  // /clients page
  name: "Nom",
  // Pagination component
  pagination_title: "Données paginées",
  // /delegates page
  delegates_description: "Une DPAE & Confirmation est une délégation de personnel chez un de vos clients.\nElle vous permet d'automatiser la D.P.A.E à l'URSSAF et l'envoi du bon de mission par SMS pour chaque personnel que vous déléguez",
  model: "Poste",
  customer: "Client",
  creation_date: "Date de création",
  starting_date: "Date de début",
  ending_date: "Date de fin",
  // Themes
  theme_light: "Thème clair",
  theme_dark: "Thème sombre",
  // 404 page
  not_found: "Cette page n’a pas été trouvée",
  not_found_text1: "Merci de nous faire part de cette erreur en cliquant sur le lien suivant: ",
  not_found_text2: "Accès au portail servicedesk",
  not_found_text3: "Vous avez reçu un lien pour créer votre compte sur le servicedesk. Nous traiterons cette demande dans les plus brefs délais. N'hésitez pas à faire une capture d'écran afin de nous aider à résoudre l'incident au plus vite.",
  // /login page
  login_page: {
    welcome_message: "Bienvenue sur votre espace privé Dispojob. L'accès à votre compte est confidentiel.", 
    forgot_password: "Mot de passe oublié ?",
    login: "Connexion",
    log_in: "Se connecter",
    password: "Mot de passe",
    valid_password: "Mot de passe valide",
    min_char: "6 caractères au minimum",
    brochure: "Catalogue de formations en ligne"
  },
  // /inscription page
  subscription_page: {
    error: {
      unknown: "Bitte überprüfen Sie das Formular, indem Sie auf Zurück klicken, oder kontaktieren Sie die Raum, um Ihre Anmeldung abzuschließen.",
      "Invalid email": "Die E-Mail ist ungültig.",
      "Field space_id is required": "Bitte wählen Sie eine Raum aus.",
      "Invalid sex": "Der Anrede ist ungültig",
      "Invalid first_name": "Der Vorname ist ungültig",
      "Invalid last_name": "Der Name ist ungültig",
      "Invalid mobile_phone_number": "Die Mobiltelefonnummer ist ungültig",
      "Invalid postal_address": "Die Postanschrift ist ungültig",
      "Invalid postal_complement": "Der Adresszusatz ist ungültig.",
      "Invalid postal_code": "Die Postleitzahl ist ungültig",
      "Invalid postal_city": "Die Stadt ist ungültig",
      "Invalid postal_country": "Das Land ist ungültig",
      "Invalid birth_date (awaited format 'YYYY-MM-DD')": "Das Geburtsdatum ist ungültig",
      "Invalid birth_date (wrong value)": "Das Geburtsdatum ist ungültig",
      "Invalid birth_city": "Der Geburtsort ist ungültig",
      "Invalid birth_country": "Das Geburtsland ist ungültig",
      "Invalid nir (Pattern)": "Die Sozialversicherungsnummer ist ungültig",
      "Invalid nir (Key)": "Der Schlüssel zur Überprüfung dieser Sozialversicherungsnummer ist ungültig.",
      "email or mobile_phone_number already taken": "E-Mail oder Mobiltelefonnummer bereits verwendet",
      "Invalid favorite_jobs size": "Die gesuchten Stellen müssen zwischen 2 und 255 Zeichen lang sein.",
      "Invalid favorite_jobs type": "Die gesuchten Arbeitsplätze können keine Zahlen sein."
    },
    favorite_jobs: "Gesuchte Jobs",
    non_compliant_file_error: "Fehler, nicht konforme Datei",
    error_no_space: "Fehler, Wir haben keine Raum gefunden, die dem von Ihnen eingegebenen Link entspricht.",
    prepare_documents: "Bereiten Sie Ihre Dokumente vor",
    mandatory_documents: "Obligatorisches Dokument",
    mandatory_resume: "müssen Sie zumindest Ihren Lebenslauf einreichen",
    documents_complete_file: "Dokument für ein vollständiges Dossier",
    your_resume: "Ihr Lebenslauf",
    resume: "Lebenslauf",
    identity_document: "Identitätsnachweis",
    social_security_document: "Gesundheitskarte oder Bescheinigung",
    residence_proof:" Nachweis des Wohnsitzes",
    banque_identity: "BIB",
    work_certificate: "Arbeitszeugnis",
    training_certificate: "Bildungsbescheinigungen",
    medical_checkup: "Ärztliche Untersuchung/Abklärung",
    documents_quality_info: "Ihre Dokumente und Fotos sollten von guter Qualität sein und einen guten Bildausschnitt haben.",
    documents_format_info: "Die hochgeladenen Dateien müssen kleiner als 8 MB sein und die folgenden Formate haben: .jpg, .jpeg, .png, .pdf, .doc, .docx, .odt oder .rtf",
    complete_file_warning: "Je vollständiger Ihre Unterlagen sind, desto eher haben Sie eine Chance auf einen Arbeitsplatz.",
    file_usage:"Mit dem Absenden dieses Formulars erkläre ich mich damit einverstanden, dass die erfassten Informationen im Rahmen meiner Bewerbung, der Verwaltung meiner Beschäftigungsfähigkeit und der sich daraus möglicherweise ergebenden vertraglichen und/oder geschäftlichen Beziehungen ausgewertet werden.",
    sign_in:"Mich anmelden",
    marital_status_info: "Die folgenden Informationen werden erhoben, um Ihre Anstellung in Frankreich zu melden. Falls dies nicht auf Sie zutrifft, klicken Sie auf Weiter ",
    invalid_nir: "Diese Sozialversicherungsnummer ist ungültig",
    invalid_nir_key: "Der Prüfschlüssel für diese Sozialversicherungsnummer ist ungültig",
    other_id:"Anderer Ausweis",
    other_documents: "Anderes Dokument",
    confirmation: {
      title: "Herzlichen Glückwunsch, es ist geschafft!",
      text_1: "Bravo, die Anmeldung ist abgeschlossen.",
      text_2: "Ihr Arbeitgeber oder Personalvermittler wird sich mit Ihnen in Verbindung setzen, um den weiteren Verlauf des Einstellungsprozesses zu besprechen.",
      text_3: "Gute Chancen und erfolgreiche Rekrutierung.",
      video_1: "Video der Funktionsweise",
      video_2: "1 SMS, 1 Job!",
      new_subscription: "Neue Anmeldung"
    },
    cet_consent: "Ich möchte ein Zeitsparkonto eröffnen.",
    kiosk_subscription: "Anmeldung Kiosk",
    portrait_guide: "Platzieren Sie Ihr Gesicht in der Mitte des Objektivs",
    scanner_guide: "Legen Sie die Vorlage auf den Scannerhalter",
    webcam_error: "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Zugriffsrechte für die Webcam oder ob sie von einer anderen Quelle verwendet wird."
  },
  drag_and_drop: "Ziehen und Ablegen",
  identity_card: "Personalausweis",
  identity_card_verso: "Personalausweis Rückseite",
  passport: "Reisepass",
  caces: "CACES",
  habilitation: "Habilitation",
  license: "Lizenz",
  diploma: "Abschluss",
  driver_licence: "Führerschein",
  other: "Andere",
  subscription: "Anmeldung",
  photo: "Foto",
  birth_date:'Geburtsdatum',
  required_field: "Erforderliches Feld",
  preparation: "Vorbereitung",
  contact_details: "Kontaktdaten",
  marital_status: "Zivilstand",
  documents: "Dokumente",
  resident_permit: "Aufenthaltstitel",
  confirmation: "Bestätigung",
  continue: "Weitermachen",
  return: "Zurück",
  // Calendar
  fullcalendar: "Calendrier Complêt",
  // /calendar page
  status: "Statut",
  available: "Disponible",
  unavailable: "Indisponible",
  unknown:"unbekannt",
  contract: "En contrat",
  time_start: "Heure de début",
  time_end: "Heure de fin",
  event_created: "Évènement créé",
  event_updated: "Évènement mis à jour",
  event_deleted: "Évènement supprimé",
  // Parameters
  language: 'Langue',
  // Generic terms
  title: "Titre",
  create: "Créer",
  delete: "Supprimer",
  update: "Mettre à jour",
  close: "Fermer",
  cancel: "Annuler",
  choose_space: "Choisir un espace",
  space: "Raum",
  mandatory: "Obligatorisch",
  optional: "Fakultativ",
  required: "Erforderlich",
  email: "E-mail",
  phone: "Telefon",
  valid_email: "Gültige E-Mail-Adresse",
  invalid_email: "Ungültige E-Mail-Adresse",
  first_name: "Vorname",
  firstname: "Vorname",
  last_name: "Name",
  lastname: "Name",
  postal_address: "Postanschrift",
  postal_complement: "Ergänzung der Adresse",
  postal_code: "CPostleitzahl",
  city: "Stadt",
  country: "Land",
  civility: "Zivilität",
  mister: "Herr",
  misses: "Frau",
  // transport
  means_transport: "Fortbewegungsmittel",
  car: "Auto",
  motorbike: "Motorrad",
  bike_scooter: "Fahrrad, Tretroller",
  mass_transit: "öffentliche Verkehrsmittel",
  moped: "Motorroller",
  walk: "zu Fuß",
  carpool: "mitfahrgelegenheiten",
  password: "Passwort",
  none: "keine",
  invalid_size_array: "Sie können nicht mehr als 3 Berufe hinzufügen.",
  // mobile phone number input
  mobile_phone_number: "Mobiltelefonnummer",
  invalid_mobile_phone_number: "Ungültige Handynummer",
  invalid_length: "Die Länge des Textes ist ungültig",
  is_numeric: "Dieses Feld darf nur ganze Zahlen enthalten",
  is_string: "Dieses Feld darf keine Zahlen enthalten",
  // Planning
  week: "Semaine",
  month: "Mois",
  date_from: "Du",
  date_to: "Au",
  // Roles - Permissions
  permission: "Permission",
  permissions_names: {
    "app-admin": "Administrateur application",
    "space-users": "Administrateur espace - CRUD utilisateurs",
    "jobs-delegate": "DPAE & Confirmation de mission",
    "orders": "Commandes",
    "multiposting": "Multidiffusion"
  },
  permissions_descriptions: {
    "app-admin": "A tous les droits sur toute l'application",
    "space-users": "Vous êtes administrateur de votre Espace, vous pouvez créer des utilisateurs, réinitialiser leur mot de passe, etc...",
    "jobs-delegate": "A le droit d'utiliser la fonctionnalité DPAE & Confirmation de mission",
    "orders": "A le droit d'utiliser la fonctionnalité Commandes",
    "multiposting": "A le droit d'utiliser l'application Multidiffusion"
  },
  // Admin
  admin: {
    title: "Administration application",
    users: {
      title: "Utilisateurs",
    }
  },
  // Space administration page
  space_admin: {
    title: "Administration espace",
    users: {
      title: "Utilisateurs",
    }
  },
  // User list
  user_list: "Liste des utilisateurs",
  permissions: "Permissions",
  spaces: "Raum",
  groups: "Groupes",
  last_login_at: "Dernière connexion",
  v3_last_login_at: "Dernière connexion v3",
  deleted_at: 'Date de suppression',
  api_access: "Accès API",
  deleted_user: "Utilisateur supprimé",
  delete_users: "Supprimer les utilisateurs",
  deletion_successful: "Suppression effectuée avec succès",
  deletion_partial: "Suppression partiellement effectuée avec succès",
  // User creation
  user_creation: "Créer un utilisateur",
  user_API: "Créer un utilisateur API ?",
  is_user_admin: "Est-ce que le futur utilisateur sera un administrateur ?",
  user_successfully_created: "Utilisateur créé avec succès",
  // User_update
  user_update: "Mettre à jour un utilisateur",
  v1_admin_rights: "Donner a cet utilisateur les droits d'administration ?",
  user_successfully_updated: "Utilisateur mis à jour avec succès",
  // Paragon
  selection: "Sélection",
  select_page: "Sélectionner toute la page",
  select_all_filtered: "Sélectionner tous les résultats filtrés",
  select_file: "Wählen Sie eine Datei",
  list: "Liste",
  asc: "Ascendant",
  desc: "Descendant",
  action: "Actions",
  filter: "Filtres",
  clear_filters: "Vider les filtres",
  search: "Rechercher",
  no_data_available: "Aucune donnée disponible",
  // Date
  from: "À partir du",
  to: "Jusqu'au",
  select_only_one_date: "Vous n'avez sélectionné qu'une date.",
  filter_without_date: "Filtrer les résultats sans dates ?",
  do_you_want: "Voulez-vous",
  // Worker List
  filter_by_first_last_name: "Filtrer par noms ou prénoms",
  separate_search_with_commas: "Séparez vos éléments de recherche (noms et prénoms) par des virgules pour une recherche multiple"
}
